.account-container {
  margin: 100px; }

.account-block-1 {
  width: 100%; }

.account-block-2 {
  margin-top: 40px;
  border-radius: 15px;
  box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.2);
  padding: 40px;
  max-width: 100%; }

.account-details-1 {
  display: grid;
  width: 300px; }
