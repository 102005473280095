.business-wrapper {
  display: flex;
  flex-wrap: nowrap; }

.booking-section {
  width: 40%;
  border: 1px solid #dddddd;
  border-radius: 12px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 10px;
  margin-top: 20px;
  margin-right: 20px; }

.business-section {
  width: 60%;
  padding: 24px;
  padding-right: 80px; }

.business-image {
  width: 30%;
  border-radius: 10px; }

.business-information {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 12px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 10px;
  margin-top: 20px; }
