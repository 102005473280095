@import url(./../../styles/globals.css);
.banner-section {
  display: flex;
  min-width: 300px;
  z-index: 200;
  padding-top: 80px;
  position: relative; }

.banner-image {
  width: 100%; }

.get-started-section {
  position: absolute;
  background-color: #ffffff;
  width: 460px;
  margin-top: 75px;
  margin-left: 100px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12); }

.get-started-form {
  margin: 10px 0px 0px 0px; }

.tell-us-more {
  margin: 10px;
  font-size: 12px;
  text-decoration: underline; }

.section-1 {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  color: #333333; }

.why-section {
  width: 50%;
  text-align: center; }

.guarantee-section {
  width: 50%;
  text-align: center; }

.low-fee-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%; }

.deposit-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%; }

.payouts-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%; }

.section-2 {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  color: #333333;
  margin: auto;
  text-align: center; }

.text-body {
  text-align: left;
  padding-left: 15%;
  padding-right: 15%; }

.text-body-2 {
  text-align: left; }

.section-headers {
  margin-top: 120px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 60px;
  line-height: 64px;
  letter-spacing: -2.5px;
  font-weight: 600;
  color: #333333; }

.section-3 {
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  color: #333333;
  text-align: center; }

.free-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 15px; }

.basic-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 15px; }

.executive-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 15px; }
