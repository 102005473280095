html {
  font-size: 100%; }

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5; }

body,
input::placeholder,
input,
button {
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important; }

button:disabled {
  opacity: 0.5; }

a:visited {
  color: inherit; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  font-family: "Montserrat" !important;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement .InputElement::placeholder {
  font-size: 50px !important;
  color: green; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.stripeCheckout {
  border-radius: 5px; }

.stripePaymentButton {
  width: 100%;
  height: 60px;
  font-size: 20px !important; }

.basketWrapper {
  width: 60%;
  min-height: 650px;
  margin: auto;
  background-color: #eee;
  padding-top: 90px;
  align-items: center;
  text-align: center;
  background-color: white; }

@media only screen and (max-width: 480px) {
  div#hubspot-messages-iframe-container {
    display: none !important; } }

@media only screen and (max-width: 768px) {
  .stripePaymentButton {
    font-size: 16px !important; }
  .basketWrapper {
    margin-top: 20px;
    width: 100%; }
  .searchbarWrapper {
    display: block !important;
    max-width: 614px; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .geoSearchbar {
    display: block !important; }
  .searchbarWrapper {
    display: block !important;
    max-width: 614px; }
  .geoSearchbarAuto {
    display: none !important; } }
