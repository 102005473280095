@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
.footer {
  min-height: 200px;
  background-color: #ececec;
  padding: 30px;
  margin-top: 100px;
  padding-top: 10px; }

.footer.mobile {
  flex-direction: column;
  padding-top: 1em;
  padding-bottom: 1em; }

.footerSections {
  display: flex;
  flex-wrap: nowrap;
  width: 100%; }

.aboutSection {
  width: 50%;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 30px;
  color: #484848; }

.hostSection {
  width: 50%;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 30px;
  color: #484848; }

.copyrightNotice {
  font-size: 14px;
  border-top: 1px solid #dddddd;
  padding-top: 20px;
  color: #484848; }

.footerLogo {
  margin-bottom: 20px; }

.copyrightNotice.mobile {
  text-align: center; }

.socialLinks.mobile {
  margin-left: 0;
  margin-bottom: 1em; }

.socialIcon {
  width: 40px;
  margin-left: 10px; }

.footerContainer {
  padding: 20px;
  flex: 1;
  height: 100%;
  text-align: center;
  min-width: 145px; }

.siteLinks {
  display: flex;
  flex-wrap: nowrap;
  color: #fff; }

.siteLinks a {
  margin: 10px; }

@media screen and (max-width: 650px) {
  .footerContainer {
    min-width: 100%; }
  .footer {
    font-size: 0.8rem;
    padding: 0; }
  .siteLinks {
    display: inline-grid;
    flex-wrap: nowrap;
    color: #fff; }
  .siteLinks a {
    margin: 10px; } }
