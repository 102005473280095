.get-started-main {
  margin: 100px; }

.get-started-title {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 20px;
  padding-left: 40px; }

.get-started-block-1 {
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.2);
  padding-top: 40px; }

.get-started-stepper {
  margin: 40px; }

.get-started-stepper-footer {
  padding-bottom: 40px; }

.business-get-started-stepper-wrapper {
  display: flex; }

.business-get-started-stepper-block {
  width: 33%;
  margin: 10px; }

.get-started-time-picker {
  width: 100%;
  margin-top: 15px; }

.rc-time-picker-input {
  height: 55px;
  border: 1px solid #cbcbcb;
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d; }

.user-details-stepper-wrapper {
  display: flex; }

.user-details-stepper-block {
  width: 33%;
  margin: 10px; }
