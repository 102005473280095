.CalendarHeader {
  width: 180px;
  padding-top: 20px;
  height: 80px;
  border: 0.5px solid #e0e0e0;
  align-items: center;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff; }

.CalendarHeaderSub {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2; }

.CalendarHeaderSub-2 {
  color: #a1aac7; }

.hour {
  width: 45px;
  height: 100px;
  border-top: 0.5px solid #e0e0e0;
  border-bottom: 0.5px solid #e0e0e0;
  /* padding: .5em; */
  align-items: center;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff; }

.onHour {
  width: 45px;
  height: 100px;
  border-left: 0.5px solid #e0e0e0;
  border-top: 0.5px solid #e0e0e0;
  border-bottom: 0.5px solid #e0e0e0;
  /* padding: .5em; */
  align-items: center;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  background-color: #fff; }

.hiddenHour {
  width: 0;
  height: 100;
  border-top: 0.5px solid #e0e0e0;
  border-bottom: 0.5px solid #e0e0e0;
  align-items: center;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff; }

.keys {
  margin-right: 10px;
  margin-left: 30px;
  width: 20px;
  height: 20px; }

.bookingsKey {
  background-color: #76d6a3; }

.subscriptionKey {
  background-color: #bfe7fb; }

.membershipKey {
  background-color: #c090dd; }

.restrictedAccessKey {
  background-color: #e0e0e0; }

.unpaidKey {
  background-color: #f9c0c0; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border: #4581e2;
  background-color: #4581e2; }

.DateInput_input__focused {
  border-bottom: 2px solid #4581e2; }

.DateInput_input {
  text-align: center;
  padding: 0px;
  padding-top: 25px; }

.DateInput {
  width: 160px; }

.SingleDatePickerInput_calendarIcon {
  margin-top: 13px; }

.SingleDatePicker {
  z-index: 2; }

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #4581e2; }

.MuiInputBase-root {
  width: 300px; }

.MuiInput-underline:after {
  border-bottom: 2px solid #4581e2 !important; }

.calendar-1 {
  overflow-x: scroll;
  display: inline-block;
  margin-top: 40px; }

.fixed_header {
  margin-top: 40px;
  width: 1560px;
  height: 600px;
  overflow: scroll;
  display: block; }

.td {
  background-color: white;
  width: 45px;
  height: 100px;
  float: left; }

.th {
  background-color: white;
  width: 180px;
  float: left; }

.thead {
  position: sticky;
  top: 0px;
  z-index: 1; }

.tr {
  display: block;
  clear: both;
  width: 2300px; }

.thead > div > div:nth-child(1) {
  left: 0;
  top: 0;
  position: sticky; }
