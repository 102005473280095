.dashboard-main {
  margin: 100px;
  margin-top: 80px; }

.dashboard-main-title {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 64px;
  letter-spacing: -2.5px;
  font-weight: 400;
  color: #333333; }

.dashboard-block-1 {
  width: 350px;
  border-radius: 10px;
  background: linear-gradient(145deg, #fafafa 0%, #f0f0f0 100%);
  border: 2px solid #eeeeee;
  padding: 20px;
  margin-right: 80px; }

.dashboard-block-2 {
  width: 350px;
  border-radius: 10px;
  padding: 20px;
  margin-right: 80px; }

.dashboard-block-1-title {
  font-size: 30px;
  line-height: 64px;
  letter-spacing: -2.5px;
  font-weight: 600;
  color: #333333; }

.dashboard-block-1-body {
  color: #666666; }

.dashboard-block-1-body-2 {
  margin-top: 20px;
  color: #666666; }

.dashboard-upnext-card {
  display: flex;
  width: 310px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 5px;
  border: 2px solid #e9e9e9;
  margin-bottom: 20px; }

.dashboard-upnext-card-left {
  width: 50px; }

.dashboard-upnext-card-right {
  width: 200px;
  display: flex;
  flex-wrap: wrap; }

.dashboard-upnext-card-title {
  color: #666666; }

.dashboard-upnext-card-sub-desctiption {
  font-size: 10px;
  color: #00a054; }

.dashboard-upnext-card-image {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: #df5d66;
  text-align: center;
  font-size: 26px;
  color: #ffffff; }

.dashboard-upnext-card-image-2 {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: #028ef2;
  text-align: center;
  font-size: 26px;
  color: #ffffff; }

.dashboard-blocks {
  display: flex; }

.dashboard-summary-card-wrapper {
  display: flex;
  flex-wrap: wrap; }

.dashboard-summary-card-contents {
  margin-top: 16px; }

.dashboard-summary-card-1 {
  width: 135px;
  height: 60px;
  background-color: #e2f6ff;
  border-radius: 10px;
  color: #1764ff;
  font-weight: 600;
  padding: 10px;
  margin: 10px;
  position: relative; }

.dashboard-summary-card-2 {
  width: 135px;
  height: 60px;
  background-color: #def8e8;
  border-radius: 10px;
  color: #00a054;
  font-weight: 600;
  padding: 10px;
  margin: 10px; }

.dashboard-summary-card-3 {
  width: 135px;
  height: 60px;
  background-color: #f6f2ff;
  border-radius: 10px;
  color: #643ac1;
  font-weight: 600;
  padding: 10px;
  margin: 10px; }

.dashboard-summary-card-4 {
  width: 135px;
  height: 60px;
  background-color: #ffefe8;
  border-radius: 10px;
  color: #ff350d;
  font-weight: 600;
  padding: 10px;
  margin: 10px; }
