.DateInput {
  font-size: 16px !important;
  width: 81% !important;
  border-bottom: 0 !important; }

.SingleDatePickerInput {
  width: 100% !important;
  height: 50px; }

.DateInput_input {
  font-size: 16px !important;
  width: 100% !important;
  border-bottom: 0 !important;
  height: 46px; }

.SingleDatePicker_picker__fullScreenPortal {
  z-index: 10000000 !important; }

.CalendarDay__highlighted_calendar {
  background-color: #eee !important;
  color: #aaa !important; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #24dc89 !important; }

.DateInput_input,
.DateInput_input__small {
  color: #9b9b9b !important; }

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #24dc89 !important; }

a:link,
a:visited {
  color: inherit; }

header {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2) !important; }

.MuiButton-text {
  padding: 8px 16px !important; }

.MuiButton-textPrimary {
  color: #fff !important; }

@media screen and (min-width: 1025px) {
  .DateInput_input {
    width: 70% !important; } }
