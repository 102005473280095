@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);
html {
  font-size: 100%; }

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5; }

input::-webkit-input-placeholder {
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important; }

input:-ms-input-placeholder {
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important; }

input::-ms-input-placeholder {
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important; }

body,
input::placeholder,
input,
button {
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important; }

button:disabled {
  opacity: 0.5; }

a:visited {
  color: inherit; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  font-family: "Montserrat" !important;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease; }

.StripeElement .InputElement::-webkit-input-placeholder {
  font-size: 50px !important;
  color: green; }

.StripeElement .InputElement:-ms-input-placeholder {
  font-size: 50px !important;
  color: green; }

.StripeElement .InputElement::-ms-input-placeholder {
  font-size: 50px !important;
  color: green; }

.StripeElement .InputElement::placeholder {
  font-size: 50px !important;
  color: green; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.stripeCheckout {
  border-radius: 5px; }

.stripePaymentButton {
  width: 100%;
  height: 60px;
  font-size: 20px !important; }

.basketWrapper {
  width: 60%;
  min-height: 650px;
  margin: auto;
  background-color: #eee;
  padding-top: 90px;
  align-items: center;
  text-align: center;
  background-color: white; }

@media only screen and (max-width: 480px) {
  div#hubspot-messages-iframe-container {
    display: none !important; } }

@media only screen and (max-width: 768px) {
  .stripePaymentButton {
    font-size: 16px !important; }
  .basketWrapper {
    margin-top: 20px;
    width: 100%; }
  .searchbarWrapper {
    display: block !important;
    max-width: 614px; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .geoSearchbar {
    display: block !important; }
  .searchbarWrapper {
    display: block !important;
    max-width: 614px; }
  .geoSearchbarAuto {
    display: none !important; } }

.DateInput {
  font-size: 16px !important;
  width: 81% !important;
  border-bottom: 0 !important; }

.SingleDatePickerInput {
  width: 100% !important;
  height: 50px; }

.DateInput_input {
  font-size: 16px !important;
  width: 100% !important;
  border-bottom: 0 !important;
  height: 46px; }

.SingleDatePicker_picker__fullScreenPortal {
  z-index: 10000000 !important; }

.CalendarDay__highlighted_calendar {
  background-color: #eee !important;
  color: #aaa !important; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #24dc89 !important; }

.DateInput_input,
.DateInput_input__small {
  color: #9b9b9b !important; }

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #24dc89 !important; }

a:link,
a:visited {
  color: inherit; }

header {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2) !important; }

.MuiButton-text {
  padding: 8px 16px !important; }

.MuiButton-textPrimary {
  color: #fff !important; }

@media screen and (min-width: 1025px) {
  .DateInput_input {
    width: 70% !important; } }




.banner-section {
  display: flex;
  min-width: 300px;
  z-index: 200;
  padding-top: 80px;
  position: relative; }

.banner-image {
  width: 100%; }

.get-started-section {
  position: absolute;
  background-color: #ffffff;
  width: 460px;
  margin-top: 75px;
  margin-left: 100px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12); }

.get-started-form {
  margin: 10px 0px 0px 0px; }

.tell-us-more {
  margin: 10px;
  font-size: 12px;
  text-decoration: underline; }

.section-1 {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  color: #333333; }

.why-section {
  width: 50%;
  text-align: center; }

.guarantee-section {
  width: 50%;
  text-align: center; }

.low-fee-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%; }

.deposit-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%; }

.payouts-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%; }

.section-2 {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  color: #333333;
  margin: auto;
  text-align: center; }

.text-body {
  text-align: left;
  padding-left: 15%;
  padding-right: 15%; }

.text-body-2 {
  text-align: left; }

.section-headers {
  margin-top: 120px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 60px;
  line-height: 64px;
  letter-spacing: -2.5px;
  font-weight: 600;
  color: #333333; }

.section-3 {
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  color: #333333;
  text-align: center; }

.free-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 15px; }

.basic-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 15px; }

.executive-section {
  width: 40%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: #f8f8f8;
  border-radius: 15px;
  margin: 15px; }

.business-wrapper {
  display: flex;
  flex-wrap: nowrap; }

.booking-section {
  width: 40%;
  border: 1px solid #dddddd;
  border-radius: 12px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 10px;
  margin-top: 20px;
  margin-right: 20px; }

.business-section {
  width: 60%;
  padding: 24px;
  padding-right: 80px; }

.business-image {
  width: 30%;
  border-radius: 10px; }

.business-information {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 12px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 10px;
  margin-top: 20px; }

.get-started-main {
  margin: 100px; }

.get-started-title {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 20px;
  padding-left: 40px; }

.get-started-block-1 {
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.2);
  padding-top: 40px; }

.get-started-stepper {
  margin: 40px; }

.get-started-stepper-footer {
  padding-bottom: 40px; }

.business-get-started-stepper-wrapper {
  display: flex; }

.business-get-started-stepper-block {
  width: 33%;
  margin: 10px; }

.get-started-time-picker {
  width: 100%;
  margin-top: 15px; }

.rc-time-picker-input {
  height: 55px;
  border: 1px solid #cbcbcb;
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d; }

.user-details-stepper-wrapper {
  display: flex; }

.user-details-stepper-block {
  width: 33%;
  margin: 10px; }

.account-container {
  margin: 100px; }

.account-block-1 {
  width: 100%; }

.account-block-2 {
  margin-top: 40px;
  border-radius: 15px;
  box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.2);
  padding: 40px;
  max-width: 100%; }

.account-details-1 {
  display: grid;
  width: 300px; }

.footer {
  min-height: 200px;
  background-color: #ececec;
  padding: 30px;
  margin-top: 100px;
  padding-top: 10px; }

.footer.mobile {
  flex-direction: column;
  padding-top: 1em;
  padding-bottom: 1em; }

.footerSections {
  display: flex;
  flex-wrap: nowrap;
  width: 100%; }

.aboutSection {
  width: 50%;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 30px;
  color: #484848; }

.hostSection {
  width: 50%;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 30px;
  color: #484848; }

.copyrightNotice {
  font-size: 14px;
  border-top: 1px solid #dddddd;
  padding-top: 20px;
  color: #484848; }

.footerLogo {
  margin-bottom: 20px; }

.copyrightNotice.mobile {
  text-align: center; }

.socialLinks.mobile {
  margin-left: 0;
  margin-bottom: 1em; }

.socialIcon {
  width: 40px;
  margin-left: 10px; }

.footerContainer {
  padding: 20px;
  flex: 1 1;
  height: 100%;
  text-align: center;
  min-width: 145px; }

.siteLinks {
  display: flex;
  flex-wrap: nowrap;
  color: #fff; }

.siteLinks a {
  margin: 10px; }

@media screen and (max-width: 650px) {
  .footerContainer {
    min-width: 100%; }
  .footer {
    font-size: 0.8rem;
    padding: 0; }
  .siteLinks {
    display: inline-grid;
    flex-wrap: nowrap;
    color: #fff; }
  .siteLinks a {
    margin: 10px; } }


.dashboard-main {
  margin: 100px;
  margin-top: 80px; }

.dashboard-main-title {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 64px;
  letter-spacing: -2.5px;
  font-weight: 400;
  color: #333333; }

.dashboard-block-1 {
  width: 350px;
  border-radius: 10px;
  background: linear-gradient(145deg, #fafafa 0%, #f0f0f0 100%);
  border: 2px solid #eeeeee;
  padding: 20px;
  margin-right: 80px; }

.dashboard-block-2 {
  width: 350px;
  border-radius: 10px;
  padding: 20px;
  margin-right: 80px; }

.dashboard-block-1-title {
  font-size: 30px;
  line-height: 64px;
  letter-spacing: -2.5px;
  font-weight: 600;
  color: #333333; }

.dashboard-block-1-body {
  color: #666666; }

.dashboard-block-1-body-2 {
  margin-top: 20px;
  color: #666666; }

.dashboard-upnext-card {
  display: flex;
  width: 310px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 5px;
  border: 2px solid #e9e9e9;
  margin-bottom: 20px; }

.dashboard-upnext-card-left {
  width: 50px; }

.dashboard-upnext-card-right {
  width: 200px;
  display: flex;
  flex-wrap: wrap; }

.dashboard-upnext-card-title {
  color: #666666; }

.dashboard-upnext-card-sub-desctiption {
  font-size: 10px;
  color: #00a054; }

.dashboard-upnext-card-image {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: #df5d66;
  text-align: center;
  font-size: 26px;
  color: #ffffff; }

.dashboard-upnext-card-image-2 {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: #028ef2;
  text-align: center;
  font-size: 26px;
  color: #ffffff; }

.dashboard-blocks {
  display: flex; }

.dashboard-summary-card-wrapper {
  display: flex;
  flex-wrap: wrap; }

.dashboard-summary-card-contents {
  margin-top: 16px; }

.dashboard-summary-card-1 {
  width: 135px;
  height: 60px;
  background-color: #e2f6ff;
  border-radius: 10px;
  color: #1764ff;
  font-weight: 600;
  padding: 10px;
  margin: 10px;
  position: relative; }

.dashboard-summary-card-2 {
  width: 135px;
  height: 60px;
  background-color: #def8e8;
  border-radius: 10px;
  color: #00a054;
  font-weight: 600;
  padding: 10px;
  margin: 10px; }

.dashboard-summary-card-3 {
  width: 135px;
  height: 60px;
  background-color: #f6f2ff;
  border-radius: 10px;
  color: #643ac1;
  font-weight: 600;
  padding: 10px;
  margin: 10px; }

.dashboard-summary-card-4 {
  width: 135px;
  height: 60px;
  background-color: #ffefe8;
  border-radius: 10px;
  color: #ff350d;
  font-weight: 600;
  padding: 10px;
  margin: 10px; }

.CalendarHeader {
  width: 180px;
  padding-top: 20px;
  height: 80px;
  border: 0.5px solid #e0e0e0;
  align-items: center;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff; }

.CalendarHeaderSub {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2; }

.CalendarHeaderSub-2 {
  color: #a1aac7; }

.hour {
  width: 45px;
  height: 100px;
  border-top: 0.5px solid #e0e0e0;
  border-bottom: 0.5px solid #e0e0e0;
  /* padding: .5em; */
  align-items: center;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff; }

.onHour {
  width: 45px;
  height: 100px;
  border-left: 0.5px solid #e0e0e0;
  border-top: 0.5px solid #e0e0e0;
  border-bottom: 0.5px solid #e0e0e0;
  /* padding: .5em; */
  align-items: center;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  background-color: #fff; }

.hiddenHour {
  width: 0;
  height: 100;
  border-top: 0.5px solid #e0e0e0;
  border-bottom: 0.5px solid #e0e0e0;
  align-items: center;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff; }

.keys {
  margin-right: 10px;
  margin-left: 30px;
  width: 20px;
  height: 20px; }

.bookingsKey {
  background-color: #76d6a3; }

.subscriptionKey {
  background-color: #bfe7fb; }

.membershipKey {
  background-color: #c090dd; }

.restrictedAccessKey {
  background-color: #e0e0e0; }

.unpaidKey {
  background-color: #f9c0c0; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border: #4581e2;
  background-color: #4581e2; }

.DateInput_input__focused {
  border-bottom: 2px solid #4581e2; }

.DateInput_input {
  text-align: center;
  padding: 0px;
  padding-top: 25px; }

.DateInput {
  width: 160px; }

.SingleDatePickerInput_calendarIcon {
  margin-top: 13px; }

.SingleDatePicker {
  z-index: 2; }

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #4581e2; }

.MuiInputBase-root {
  width: 300px; }

.MuiInput-underline:after {
  border-bottom: 2px solid #4581e2 !important; }

.calendar-1 {
  overflow-x: scroll;
  display: inline-block;
  margin-top: 40px; }

.fixed_header {
  margin-top: 40px;
  width: 1560px;
  height: 600px;
  overflow: scroll;
  display: block; }

.td {
  background-color: white;
  width: 45px;
  height: 100px;
  float: left; }

.th {
  background-color: white;
  width: 180px;
  float: left; }

.thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1; }

.tr {
  display: block;
  clear: both;
  width: 2300px; }

.thead > div > div:nth-child(1) {
  left: 0;
  top: 0;
  position: -webkit-sticky;
  position: sticky; }

